<template>
    <div class="progress mx-auto" :data-value='label'>
        <span class="progress-left">
            <span :style="'transform: rotate('+left+'deg)'" class="progress-bar "></span>
        </span>
        <span  class="progress-right">
            <span :style="'transform: rotate('+right+'deg)'" class="progress-bar"></span>
        </span>
        <div class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
            <div class="title-circle">{{label}} %
                <p>{{$t('sync.circle')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BarCircle',
    props:{
        label: String,
    },
    data(){
        return{
            left: '',
            right: ''
        }
    },
    methods:{
        percentageToDegrees(percentage) {
            return percentage / 100 * 360
        },
        prueba12(){
            if (this.label > 0) {
                if (this.label <= 50) {
                    this.right = this.percentageToDegrees(this.label)
                } else {
                    this.right = '180' 
                    // .css('transform', 'rotate(180deg)')
                    this.left = this.percentageToDegrees(this.label - 50)
                }
            }
        }
    },
    mounted(){
        this.prueba12();
    },
    watch: {
        label(){
            this.prueba12()
        }
    }
    
}
</script>