<template>
  <div class="container estadisticas sync mt-4">
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <transition name="fade">
            <div class="video" v-if="synCro">
              <h3 class="text-center">{{$t('sync.titlevideo')}}</h3>
              <div class="conte1">
                <p>{{$t('sync.desvideo')}}</p>
                <a href="https://www.youtube.com/watch?v=59m39yLQjio" class="btn-blue" target="_blank" rel="noopener noreferrer">{{$t('sync.btnvideo')}}</a>
              </div>
              <div class="conte1">
                <p>{{$t('sync.desvideo2')}}</p>
                <router-link :to="$i18nRoute({name: 'Create Search'})" class="btn-blue" style="font-size:20px">
                    {{$t('sync.btnvideo2')}}
                </router-link>
              </div>
            </div>
          </transition>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="prueba-tab" data-toggle="tab" href="#prueba" role="tab" aria-controls="prueba" aria-selected="false">
                <font-awesome-icon :icon="['fab', 'facebook']" />
                <span>Facebook</span></a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                <font-awesome-icon :icon="['fab', 'instagram']" />
                <span>Instagram</span></a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
                <font-awesome-icon :icon="['fab', 'twitter']" size="1x" />
                <span>Twitter</span></a>
            </li>
          </ul>
          <div class="tab-content" id="SyncTab">
            <div class="tab-pane fade show active" id="prueba" role="tabpanel" aria-labelledby="prueba-tab">
              <div class="text-center">
                <div class="titletab">
                    {{$t('sync.title')}}
                </div>
                <p>{{$t('sync.subtitle1')}}</p>
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-4">
                    <!-- enviar el valor al componente barCicle -->
                    <bar-circle :label="SyncFacebook"></bar-circle>
                    <div class="group">
                      <p>{{$t('sync.item1')}}</p>
                      <span :class="'fas '+sync1Face"></span>
                    </div>
                    <div class="group">
                      <p>{{$t('sync.item2')}} </p>
                      <span :class="'fas '+sync2Face"></span>
                    </div>
                    <div class="group">
                      <p>{{$t('sync.item3')}} </p>
                      <span :class="'fas '+sync3Face"></span>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3">
                    <div class="img-sync">
                      <img alt="Sync Facebook" src="../assets/image/Sync-Facebook.png"/>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div class="text-center">
                <div class="titletab">
                    {{$t('sync.title')}}
                </div>
                <p>{{$t('sync.subtitle2')}}</p>
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-4">
                    <!-- enviar el valor al componente barCicle -->
                    <bar-circle :label="SyncInstagram"></bar-circle>
                    <div class="group">
                      <p>{{$t('sync.item1')}}</p>
                      <span :class="'fas '+sync1Ins"></span>
                    </div>
                    <div class="group">
                      <p>{{$t('sync.item2')}}</p>
                      <span :class="'fas '+sync2Ins"></span>
                    </div>
                    <div class="group">
                      <p>G{{$t('sync.item3')}}</p>
                      <span :class="'fas '+sync3Ins"></span>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3">
                    <div class="img-sync">
                      <img alt="Sync Facebook" src="../assets/image/Sync-Instagram.png"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
              <div class="text-center">
                <div class="titletab">
                    {{$t('sync.title')}}
                </div>
                <p>{{$t('sync.subtitle3')}}</p>
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-4">
                    <!-- enviar el valor al componente barCicle -->
                    <bar-circle :label="SyncTwitter"></bar-circle>
                    <div class="group">
                      <p>{{$t('sync.item1')}}</p>
                      <span :class="'fas '+sync1Tw"></span>
                    </div>
                    <div class="group">
                      <p>{{$t('sync.item2')}}</p>
                      <span :class="'fas '+sync2Tw"></span>
                    </div>
                    <div class="group">
                      <p>{{$t('sync.item3')}}</p>
                      <span :class="'fas '+sync3Tw"></span>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3">
                    <div class="img-sync">
                      <img alt="Sync Facebook" src="../assets/image/Sync-Twitter.png"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dashboard {
  padding-left: 50px;
  padding-right: 50px;
}
</style>

<script>
// @ is an alias to /src

import Card from "@/components/card/Card.component.vue";
import { mapMutations, mapState } from "vuex";
import BarCircle from "@/components/barCircle/BarCircle.component.vue";
export default {
  name: "Estadisticas",
  components: {
    Card,
    BarCircle
  },
  data() {
    return {
      sync1Face: '',
      sync2Face: '',
      sync3Face: '',
      sync1Ins: '',
      sync2Ins: '',
      sync3Ins: '',
      sync1Tw: '',
      sync2Tw: '',
      sync3Tw: '',
      SyncFacebook: '0',
      SyncInstagram: '0',
      SyncTwitter: '0',
      synCro: false
    };
  },
  computed: {
    ...mapState(["social"]),
  },
  async mounted() {
    console.log("Calls");
    const data =  this.social.filter(item => item.view == true)
    const id_empresa =  data.shift().Id_Empresa;
    let finish_sync = false;
    while(await new Promise(resolve => setTimeout(() => resolve(!finish_sync), 1000))) {
      // Check the status of the sync
      const sync_data = await this.axios.post(`/bextsocial/syncaccounts/jobstatus`, {
        id_empresa: id_empresa,
        id_sync: this.$route.params.id,
      });
      console.log(sync_data.data);
      // If not have any data, finish the cycle
      if(Object.keys(sync_data.data).length === 0){
        finish_sync = true;
        this.$fire({
            title: "¡Error de conexión!",
            type: "info",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'OK',
            html: "<p>No se logra obtener información de está vinculación</p>"
        }).then(async r => {
            console.log(r);
        })
      }
      // Check status and percentage
      finish_sync = this.checkStatus(sync_data.data);
    }
    const respuesta = await this.file()
    console.log(respuesta);
    this.synCro = true


  },
  methods: {
    ...mapMutations(["Carga"]),
    //insert file to storage
    async file() {
      try {
        var file = new File(["foo"], "foo.txt", {
          type: "text/plain",
        });
        //     let formData = new FormData();
        //     formData.append('file', file);
        //    let axiosConfig = {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     };
        //    const response = await this.axios.post('/bextsocial/storage', {
        //        image: file
        //    }, axiosConfig)
        const token = localStorage.getItem("id_token");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        var formdata = new FormData();
        formdata.append("image", file, "BextSocial.txt");

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        // api construida con fetch para enviar el archivo
        const response = await fetch('https://bextsocial.com/api/bextsocial/storage', requestOptions);
        // const response = await fetch('https://bextsocialapp.azurewebsites.net/api/bextsocial/storage', requestOptions);
        // const response = await fetch('https://localhost:3000/api/bextsocial/storage', requestOptions);
        console.log(response);
        const dataemp =  this.social.filter(item => item.view == true)
        const id_empresa =  dataemp.shift().Id_Empresa;
        const {data} = await this.axios.post('/bextsocial/datafactory',{
          id: id_empresa
        })
        console.log(data);
        return response
      } catch (error) {
        console.log(error);
        return error
      }
      
    },
    checkStatus(jobs_data){
      // Checkby key to set the structure
      let keys = Object.keys(jobs_data);
      let all_finished = [];
      for(let key of keys){
        switch (key) {
          case "facebook":
            all_finished.push(this.processFbStatus(jobs_data[key]));
          break;
          case "instagram":
            all_finished.push(this.processInstagram(jobs_data[key]));
          break;
          case "twitter":
            all_finished.push(this.processTwitter(jobs_data[key]));
          break;
        }
      }
      // If all the elements are done break the cycle
      var completed_elements = all_finished.every(function(element){
        return element === true
      });
      // Return if the cycle must continue or not
      return completed_elements;
    },
    processFbStatus(fb_data){
      // Set the percentage of progress
      this.SyncFacebook = fb_data.progress.toString();
      // Set the buttons
      this.setFbButtons(fb_data.status);
      // Check if finished or not
      return (true) ? fb_data.status == 'Complete' || fb_data.status == 'Fail' : false;
    },
    processInstagram(instagram_data){
      // Set the percentage of progress
      this.SyncInstagram = instagram_data.progress.toString();
      // Set the buttons
      this.setInstagramButtons(instagram_data.status);
      // Check if finished or not
      return (true) ? instagram_data.status == 'Complete' || instagram_data.status == 'Fail' : false;
    },
    processTwitter(twitter_data){
      // Set the percentage of progress
      this.SyncTwitter = twitter_data.progress.toString();
      // Set the buttons
      this.setTwitterButtons(twitter_data.status);
      // Check if finished or not
      return (true) ? twitter_data.status == 'Complete' || twitter_data.status == 'Fail' : false;
    },
    setFbButtons(status) {
      // Depends on the status set the css styles
      switch (status) {
        case "Download Stats":
          this.sync1Face = 'fa-check-circle'
          this.sync2Face = ''
          this.sync3Face = ''
        break;
        case "Download Data":
          this.sync1Face = 'fa-check-circle'
          this.sync2Face = 'fa-check-circle'
          this.sync3Face = ''
        break;
        case "Save Data":
          this.sync1Face = 'fa-check-circle'
          this.sync2Face = 'fa-check-circle'
          this.sync3Face = 'fa-check-circle'
        break;
        case "Complete":
          this.sync1Face = 'fa-check-circle'
          this.sync2Face = 'fa-check-circle'
          this.sync3Face = 'fa-check-circle'
        break;
        case "Fail":
          this.sync1Face = 'fa-check-circle'
          this.sync2Face = 'fa-check-circle'
          this.sync3Face = 'fa-check-circle'
        break;
      }
    },
    setInstagramButtons(status) {
      // Depends on the status set the css styles
      switch (status) {
        case "Download Stats":
          this.sync1Ins = 'fa-check-circle'
          this.sync2Ins = ''
          this.sync3Ins = ''
        break;
        case "Download Data":
          this.sync1Ins = 'fa-check-circle'
          this.sync2Ins = 'fa-check-circle'
          this.sync3Ins = ''
        break;
        case "Save Data":
          this.sync1Ins = 'fa-check-circle'
          this.sync2Ins = 'fa-check-circle'
          this.sync3Ins = 'fa-check-circle'
        break;
        case "Complete":
          this.sync1Ins = 'fa-check-circle'
          this.sync2Ins = 'fa-check-circle'
          this.sync3Ins = 'fa-check-circle'
        break;
        case "Fail":
          this.sync1Ins = 'fa-check-circle'
          this.sync2Ins = 'fa-check-circle'
          this.sync3Ins = 'fa-check-circle'
        break;
      }
    },
    setTwitterButtons(status) {
      // Depends on the status set the css styles
      switch (status) {
        case "Download Stats":
          this.sync1Tw = 'fa-check-circle'
          this.sync2Tw = ''
          this.sync3Tw = ''
        break;
        case "Download Data":
          this.sync1Tw = 'fa-check-circle'
          this.sync2Tw = 'fa-check-circle'
          this.sync3Tw = ''
        break;
        case "Save Data":
          this.sync1Tw = 'fa-check-circle'
          this.sync2Tw = 'fa-check-circle'
          this.sync3Tw = 'fa-check-circle'
        break;
        case "Complete":
          this.sync1Tw = 'fa-check-circle'
          this.sync2Tw = 'fa-check-circle'
          this.sync3Tw = 'fa-check-circle'
        break;
        case "Fail":
          this.sync1Tw = 'fa-check-circle'
          this.sync2Tw = 'fa-check-circle'
          this.sync3Tw = 'fa-check-circle'
        break;
      }
    }
  }
}
</script>